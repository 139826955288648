.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;











.about              {
    img             {max-width: 100%!important; width: auto;margin: .4rem auto!important;}
}
